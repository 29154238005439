import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { EnvironmentConfig } from '../../utils/config/environment.config'

export const reduxApiService = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: EnvironmentConfig.backendUrl,
    prepareHeaders: (headers, { getState }) => {
      headers.set("Access-Control-Allow-Origin", "*")
      headers.set("Access-Control-Allow-Header", 'POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method');
      headers.set("Content-Type", "multipart/form-data")
      return headers
    },
  }),
  endpoints: () => ({}),
})