import { configureStore, combineReducers, AnyAction } from "@reduxjs/toolkit"

import layoutSlice from "../features/layout/layout.slice"
import { reduxApiService } from "../services/reduxApi.service"

const combinedReducer = combineReducers({
  [reduxApiService.reducerPath]: reduxApiService.reducer,
  layout: layoutSlice,
})

const rootReducer = (state: any, action: AnyAction) => {
  if (action.type === 'auth/setCredentials') {
    state = undefined
  }
  return combinedReducer(state, action)
}

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(reduxApiService.middleware),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

