import { Routes, Route } from "react-router-dom";

import { AppPaths } from "../../utils/mapping/appRoutes.mapping";
import { PublicLayout } from "../layouts";
import { ClaimFormPage, NotFoundPage } from "../pages";

const Routing = () => {
  return (
    <Routes>
      <Route path={AppPaths.root}>
        <Route path={AppPaths.empty} element={<PublicLayout />}>
          <Route index element={<ClaimFormPage />} />
        </Route>
        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Routes>
  );
};

export default Routing;
