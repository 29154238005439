import Loadable from "react-loadable";

import NextGlobalLoader from "../../components/next/NextGlobalLoader";

// Exceptions pages
export const NotFoundPage = Loadable({
  loader: () => import("./Exceptions/NotFoundPage"),
  loading: NextGlobalLoader,
});

// Outer pages
export const ClaimFormPage = Loadable({
  loader: () => import("./Claim"),
  loading: NextGlobalLoader,
});